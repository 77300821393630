import React, { useContext, useEffect, useState } from 'react'
import { store } from '../../App'
import { Carousel, Container, Row, Col, Button, Card } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import { GiWireframeGlobe } from 'react-icons/gi'
import NavDropdown from 'react-bootstrap/NavDropdown';
import GlobalNetworkSection from './Globalcontent';
import WorldMap from './Globalcontent';
import { FaArrowRightLong } from "react-icons/fa6";
import Footer from './Footer';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ProjectSection from '../../Owlcarojel';
import Globalcontent from './Globalcontent';
import New from '../New';
import CountUp from 'react-countup';
import { IoLocationSharp } from "react-icons/io5";
import { GoProject } from "react-icons/go";
import { MdOutlinePeople } from "react-icons/md";
import { IoPricetags } from "react-icons/io5";
import 'aos/dist/aos.css'; // Import AOS CSS

import AOS from 'aos';
import MyCarousel from './Carousel';
import axios from 'axios';


function Hompage() {
  const [menus, setMenus] = useContext(store);
  const [option, setOption] = useState()


  useEffect(() => {
    AOS.init();
  }, []);

  const handleCarouselSlide = () => {
    AOS.refresh(); // Refresh AOS animations on carousel slide
  };
  const options = {
    items: 3,
    loop: true,
    margin: 10,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  const projects = [
    {
      image: 'https://www.hyundaielevator.co.kr/upload/image/20180725_174359_870.jpg',
      category: 'interiors',
      title: 'Lower River Street Astoria',
    },
    {
      image: 'https://www.hyundaielevator.co.kr/upload/image/20180725_174359_870.jpg',
      category: 'interiors',
      title: 'Lower River Street Astoria',
    },
    {
      image: 'https://www.hyundaielevator.co.kr/upload/image/20180725_174359_870.jpg',
      category: 'interiors',
      title: 'Lower River Street Astoria',
    },
    {
      image: 'https://www.hyundaielevator.co.kr/upload/image/20180725_174359_870.jpg',
      category: 'interiors',
      title: 'Lower River Street Astoria',
    },

    // Add more projects as needed
  ];
  const myStyle = {
    transform: 'translate3d(-1233px, 0px, 0px)',
    transition: 'all 0s ease 0s',
    width: '4009px',
  };

  const [countReached25, setCountReached25] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountReached25(true);
    }, 5750); // Adjust this value according to your CountUp duration

    return () => clearTimeout(timer);
  }, []);
  const handleCountUpEnd = () => {
    setCountReached25(true);
  };


  return (
    <>








      <MyCarousel />

      <section className="team spad mt-5 " >
        <Container>
          <Row>
            <Col lg={12} md={12} sm={6}>
              <div className="section-title mb-4">
                <p className='mb-5'>View All Business Lines</p>

                {/* <h2></h2> */}
              </div>
            </Col>

          </Row>
          <Row>
            <Col lg={4} md={4} data-aos="zoom-in-up" style={{ paddingLeft: "7px" }}>
              <div className="team__item set-bg " style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img02.jpg)' }}>
                <div className="team__text">
                  <div className="team__title">
                    <h5>Products</h5>
                    <span>Elevators</span>
                  </div>
                  <p>Hyundai Elevator develops products appropriate for building designs and elevator uses so as to provide customized solutions.
                    Not only safety and ride quality but space efficiency is taken into account to provide you with optimal products that will raise the value of buildings with exquisite designs and convenient functions.</p>
                  <div className="team__social">
                    <a href="#"><i className="fa fa-facebook"></i></a>
                    <a href="#"><i className="fa fa-twitter"></i></a>
                    <a href="#"><i className="fa fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} data-aos="zoom-in-up">
              <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img03.jpg)' }}>
                <div className="team__text">
                  <div className="team__title">
                    <h5>Service</h5>
                    <span>Elevators</span>
                  </div>
                  <p>Elevators and escalators need regular inspections and maintenance to run under optimum conditions.
                    Hyundai Elevator operates an advanced remote maintenance system (HRTS) built on Korea's IoT convergence technology.
                    We will do the best we can to ensure prompt response, excellent maintenance and differentiated service quality.</p>
                  <div className="team__social">
                    <a href="#"><i className="fa fa-facebook"></i></a>
                    <a href="#"><i className="fa fa-twitter"></i></a>
                    <a href="#"><i className="fa fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} md={4} data-aos="zoom-in-up" style={{ paddingRight: "7px" }}>
              <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img04.jpg)' }}>
                <div className="team__text">
                  <div className="team__title">
                    <h5>Manufacturing</h5>
                    <span>Elevators</span>
                  </div>
                  <p>Ageing elevators need replacement as well as design upgrades.
                    Hyundai Elevator has the systematic expertise and extensive know-how to offer
                    reasonable modernization solutions, which will lead to reduced
                    construction costs and duration.</p>
                  <div className="team__social">
                    <a href="#"><i className="fa fa-facebook"></i></a>
                    <a href="#"><i className="fa fa-twitter"></i></a>
                    <a href="#"><i className="fa fa-instagram"></i></a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>


      <section className="background-image  mt-5" style={{ height: '350px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container>
          <Row className="d-flex justify-content-center counter-section-row">
            <Col lg={3} xs={12} md={3} className="text-center">
              {/* <div  id="foo" >
                <IoLocationSharp color='white' size={'40px'} />
              </div> */}


              <div class="circle-wrapper" data-aos="zoom-out">
                <div class="success circle"></div>
                <div class="icon">
                  <IoLocationSharp color='white' size={'40px'} />

                </div>
              </div>
              <CountUp end={2}

                duration={5.75}
                style={{ color: 'white', fontSize: '40px', width: '100px', fontWeight: "600" }}

              />
              <div>
                <h4 style={{ color: 'white', fontSize: "20px", fontWeight: "100" }}>Foot Prints</h4>
              </div>


            </Col>

            <Col lg={3} xs={12} md={3} className="text-center">
              {/* <div className='icon-counters'>
                <GoProject color='white' size={'40px'} />
              </div> */}



              <div class="circle-wrapper" data-aos="zoom-out">
                <div class="success circle"></div>
                <div class="icon">
                  <GoProject color='white' size={'40px'} />

                </div>
              </div>
              {!countReached25 ? (
                <CountUp
                  end={100}
                  duration={5.75}
                  style={{ color: 'white', fontSize: '40px', width: '100px' }}

                />
              ) : (
                <h3 style={{ color: 'white', fontSize: '40px', width: '100px', fontWeight: '600' }}>100+</h3>
              )}

              <div>
                <h4 style={{ color: 'white', fontSize: "20px", fontWeight: "100" }}>Projects</h4>
              </div>

            </Col>

            <Col lg={3} xs={12} md={3} className="text-center">
              {/* <div className='icon-counters'>
                <MdOutlinePeople color='white' size={'40px'} />
              </div> */}

              <div class="circle-wrapper" data-aos="zoom-out">
                <div class="success circle"></div>
                <div class="icon">
                  <MdOutlinePeople color='white' size={'40px'} />

                </div>
              </div>
              {/* <CountUp end={100}

                duration={5.75}
                style={{ color: 'white', fontSize: '40px', width: '100px' }}

              /> */}


              {!countReached25 ? (
                <CountUp
                  end={85}
                  duration={5.75}
                  style={{ color: 'white', fontSize: '40px', width: '100px' }}
                />
              ) : (
                <h3 style={{ color: 'white', fontSize: '40px', width: '100px', fontWeight: '600' }}>85+</h3>
              )}
              <div>
                <h4 style={{ color: 'white', fontSize: "20px", fontWeight: "100" }}>Employees</h4>
              </div>



            </Col>
            <Col lg={3} xs={12} md={3} className="text-center">
              {/* <div className='icon-counters' >
                <IoPricetags color='white' size={'40px'} />
              </div> */}

              <div class="circle-wrapper" data-aos="zoom-out">
                <div class="success circle"></div>
                <div class="icon">
                  <IoPricetags color='white' size={'40px'} />

                </div>
              </div>
              {!countReached25 ? (
                <CountUp
                  end={25}
                  duration={5.75}
                  style={{ color: 'white', fontSize: '40px', width: '100px' }}
                />
              ) : (
                <h3 style={{ color: 'white', fontSize: '40px', width: '100px', fontWeight: '600' }}>25+</h3>
              )}
              <div>
                <h4 style={{ color: 'white', fontSize: "20px", fontWeight: "100" }}>Designs</h4>
              </div>


            </Col>
          </Row>
        </Container>
      </section>


      <Container fluid >
        <Row style={{ display: 'flex', justifyContent: 'center' }}>
          <Col lg={11}>
            <section className="services spad ">
              <div className="container mb-4">
                <div className="row">
                  <div className="col-lg-12 mb-4">
                    <div className="section-title mt-5 mb-5">
                      <p>Our specialization </p>

                      {/* <h2 style={{}}>What we do</h2> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="services__item">
                      <img src="https://cdn-icons-png.freepik.com/256/11750/11750913.png" alt="" width={'65px'} />
                      <h4>Elevators</h4>
                      <p>
                        Techno Industries introduced this technology about 7 year ago and have installed it at around forty locations all over in Gujarat. All of them have been running successfully
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="services__item">
                      <img src="https://cdn-icons-png.flaticon.com/512/448/448815.png" alt="" width={'65px'} />
                      <h4>Escalators</h4>
                      <p>
                        Escalators are widely used in big malls, supermarkets, subways, railway stations, airports, business centers, and other public places.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6">
                    <div className="services__item">
                      <img src="https://cdn-icons-png.flaticon.com/512/10559/10559119.png" alt="" width={'65px'} />
                      <h4>Industrial Elevators</h4>
                      <p>
                        reight elevator (or goods lift) is an elevator designed to carry goods, rather than passengers. Freight elevators are typically larger and capable.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-6" style={{ paddingRight: '0px' }}>
                    <div className="services__item">
                      <img src="/Asests/capsule-lift-with-glass-windows-metal-details-vintage-elevator-cabin-retro-mechanical-construction-building-flat-vector-icon_22333 (1).png" alt="" width={'65px'} height={'65px'} />
                      <img src="https://img.freepik.com/premium-vector/capsule-lift-with-glass-windows-metal-details-vintage-elevator-cabin-retro-mechanical-construction-building-flat-vector-icon_223337-7210.jpg" alt="" width={'65px'} />

                      <h4>Capsule Elevator</h4>
                      <p>
                        Capsule Elevator is essentially installed at a place where elevation of building is to be enhanced with display of moving lift and passenger is provided with panoramic view of surroundings.                        </p>
                    </div>
                  </div>
                </div>
                <div className="counter__content">
                  <div className="row">
                  </div>
                </div>
              </div>
            </section>

          </Col>
        </Row>
      </Container>

      <Footer />
    </>
  )
}

export default Hompage





