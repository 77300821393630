import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Homepages/Footer';

const AboutUs = () => {
  return (
    <>
  
  
    <section className="about-page spad">
      <Container>
        <Row>
        <div className='text-center mb-4 mt-5 section-title'>
                  
                  
                  <p >About Us</p> 
                    </div>
       
          <Col lg={12} md={8}>
            <div className="about__page__services">
              <div className="about__page__services__text">
              <p>Techno Elevator develops products appropriate for building designs and elevator uses so as to provide customized solutions. Not only safety and ride quality but space efficiency is taken into account to provide you with optimal products that will raise the value of buildings with exquisite designs and convenient functions.
</p>
                <p>Elevators and escalators need regular inspections and maintenance to run under optimum conditions. Techno Elevator operates an advanced remote maintenance system. We will do the best we can to ensure prompt response, excellent maintenance and differentiated service quality.</p>
              
              </div>
              <Row className='mt-5'>
              <Col lg={4} md={6} sm={6}>
                  <div className="services__item">
                    <img className='mb-4' src="/Asests/MicrosoftTeams-image (53).png" alt="" width={'70px'} />
                    <h4 className='mb-4'>Our Vision</h4>
                    <p>A globally recognized business group creating customers for life</p>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="services__item">
                    <img className='mb-4' src="/Asests/MicrosoftTeams-image (54).png" alt="" width={'60px'} />
                    <h4 className='mb-4'>Our Mission</h4>
                    <p>Diversify and innovatively deliver world-className products and services,through empowered employees by creating value for our stakeholders</p>
                  </div>
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="services__item">
                    <img className='mb-4' src="/Asests/MicrosoftTeams-image (55).png" alt="" width={'70px'} />
                    <h4 className='mb-4'>Our Values</h4>
                    <p><li>Integrity</li></p>
                    <p><li>Ownership</li></p>
                    <p><li>Passion</li></p>
                    <p><li>Excellence</li></p>
                  </div>
                </Col>
              
              </Row>
              <div>
               
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>

    {/* <section className="callto spad set-bg" style={{backgroundImage: 'url(	https://themewagon.github.io/staging/img/call-bg.jpg)' }}>
      <Container>
        <Row className="d-flex justify-content-center">
          <Col lg={10} className="text-center">
            <div className="callto__text">
              <span>Why choose us?</span>
              <h2>Our ability to bring outstanding results to our customers.</h2>
              <a href="contact" className="primary-btn">Contact Us</a>
            </div>
          </Col>
        </Row>
      </Container>
    </section> */}


    {/* <section className="team spad mt-5 mb-5">
      <Container>
        <Row>
          <Col lg={9} md={8} sm={6}>
            
          </Col> 
        </Row>
        <Row>
          <Col lg={4} md={6}>
            <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img02.jpg)' }}>
              <div className="team__text">
                <div className="team__title">
                  <h5>Products</h5>
                  <span>Elevators</span>
                </div>
                <p>Hyundai Elevator develops products appropriate for building designs and elevator uses so as to provide customized solutions.
Not only safety and ride quality but space efficiency is taken into account to provide you with
optimal products that will raise the value of buildings with exquisite designs and convenient functions.</p>
                <div className="team__social">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img03.jpg)' }}>
              <div className="team__text">
                <div className="team__title">
                  <h5>Service</h5>
                  <span>Elevators</span>
                </div>
                <p>Elevators and escalators need regular inspections and maintenance to run under optimum conditions.
Hyundai Elevator operates an advanced remote maintenance system (HRTS) built on Korea's IoT convergence technology.
We will do the best we can to ensure prompt response, excellent maintenance and differentiated service quality.</p>
                <div className="team__social">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6}>
            <div className="team__item set-bg" style={{ backgroundImage: 'url(https://www.hyundaielevator.co.kr/assets/app/img/en/company/intro_img04.jpg)' }}>
              <div className="team__text">
                <div className="team__title">
                  <h5>Modernization</h5>
                  <span>Elevators</span>
                </div>
                <p>Ageing elevators need replacement as well as design upgrades.
Hyundai Elevator has the systematic expertise and extensive know-how to offer
reasonable modernization solutions, which will lead to reduced
construction costs and duration.</p>
                <div className="team__social">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-instagram"></i></a>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section> */}
    <Footer/>
    
    </>
  );
};

export default AboutUs;
