import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navber from './Components/Header/Navbar'
import Hompage from './Components/Homepages/Hompage';
import { createContext } from 'react';
import axios from 'axios';
import FooterNew from './Components/Homepages/Footer';
import CompanyOverview from './Components/About';
import AboutUs from './Components/About';
import InfoSection from './Components/Contact';
import ServiceSection from './Components/Service';
import News from './Components/News';
import './App.scss';
import Products from './Components/Products';
import Projects from './Components/Homepages/Projects';
import Elevator from './Components/Elevator';
import Gallary from './Components/Homepages/Gallary';
import GallaryTwo from './Components/Homepages/GallaryTwo';
import MyCarouselbootstrap from './Carojesbootstrap';
import Escalator from './Components/Escalator';




export const store = createContext()
function App() {
  const [menus, setMenus] = useState(
    {
      "items": [     
        {
          "label": "Home",
          "url": "/"
        },
        {
          "label": "About",
          "url": "about"
        },
        {
          "label": "Products",
          "url": "/products",
          "submenu":[
            {"label":"Elevators" ,"url":"/elevators"},
            {"label":"Escalators","url":"/escalators"}
  
          ]
        },
        {
          "label": "Projects",
          "url": "/projects"
        },
        {
          "label": "Services",
          "url": "/services"
        },
  
        {
          "label": "Gallery",
          "url": "/gallary"
        },
        
        
        {
          "label": "Contact",
          "url": "/contact"
        }
       
  
       
  
      
      ]
    }
    
  );
  const [isOpen, setIsOpen] = useState(false);
  const [navbarclose, setNavbarClose] = useState("navbar-open")




  // useEffect(() => {
  //   axios.get('http://localhost:4000/items')
  //     .then(response => setMenus(response.data))
  // }, [0])






  return (
    <>


    
      <store.Provider value={[menus, setMenus, isOpen, setIsOpen, navbarclose, setNavbarClose]}>
        <BrowserRouter>
          <Navber />
          <Routes>
            <Route path="/" element={<Hompage />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/contact' element={<InfoSection />} />
            <Route path='/services' element={<ServiceSection />} />
            <Route path="/news" element={<News />} />
            <Route path="/elevators" element={<Products />} />
            <Route path="/escalators" element={<Products />} />
            <Route path="/escalators" element={<Products />} />
            {/* <Route path='/gallary' element={<Gallary/>} /> */}
            <Route path='/gallary' element={<GallaryTwo />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/elevators/:subcoomponent' element={<Elevator />} />
            <Route path='/escalators/:subcoomponent' element={<Escalator />} />


          </Routes>
        </BrowserRouter>
      </store.Provider>

    </>

  );
}

export default App;
