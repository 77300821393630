import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { connect } from 'react-redux';


function Elevator() {

    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment.trim() !== '');
    const lastRouteName = pathSegments[pathSegments.length - 1];


    console.log(location)
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [lastRouteName]);

    const products = [
        {
            image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4d6de4133932561.61ca03fceac2c.jpg", title: "Capsule Elevators", link: "capsule-elevators",

            content: <div class="what_capsul">
                <h6>What is Capsule Elevator,</h6>
                <h6>How to Choose a Capsule Elevator . ?</h6><br></br>
                <p>Capsule Elevator is essentially installed at a place where elevation of building is to be enhanced with display
                    of moving lift and passenger is provided with panoramic view of surroundings. Capsule elevator normally comes in 5 and 3 Glass panel, We can also offer Tailor made glass cabin.</p>
                <p > Capsule Elevator is normally preferred in shopping malls (to give view of items displayed), 5 star hotels
                    (to provide lobby view) and high rise residential buildings. </p>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img src='https://www.technoelevators.com/images/capsule_elevator_04.png' />
                </div>
                <div className='mt-3'>
                    <p>Techno Elevator Air Conditioner is the only cost-efficient alternative for first class comfort control in today’s elevators. The lightweight, packaged unit mounts on top of the elevator and is supplied with a kit that includes everything you need for installation.</p>
                </div>
            </div>, contentimg: "https://www.technoelevators.com/images/capsule_elevator_03.png",

           
        },
        {
            image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8fa263177262991.64d383c2cc16d.gif", title: "Elevator Interior Design", link: "passenger-Elevators",
            content: <div class="what_capsul" style={{ marginTop: "20px" }}>
                <p>Opposite door operatiodemanded range of imperforated doors which are known for their unique and stylish designing. Fabricated form high quality raw material these are very popular for corrosion resistant features. </p>


                <p style={{ marginTop: "20px" }}>We further make available a customized range of imperforated doors as per client specifications and required Telescopic manual door elevator </p>


                <p style={{ marginTop: "20px" }}>We offer telescopic doors that are available in either the fixed sidelight or full breakout configuration. Our telescopic sliding doors offer smooth, quiet operation, and are ideal for spaces that require optimum opening width in all applications. </p>
                <p style={{ marginTop: "20px" }}>Our manual telescopic doors are operated manually and have also been designed according to the need and industrial demand.</p>
            </div>,
            contentimg: "https://www.technoelevators.com/images/manual_door_01.png"
        },
        {
            image: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/40b9c124798881.5633a41426cfa.jpg", title: "Elevator visualization", link: "passenger-elevators-autodoor",

            content: <div class="col-md-8">

                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%", fontWeight: '200' }} />Door Opening &amp; Closing Time</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Alarm Button</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Car Call Cancellation at Terminal Stop</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Attendant</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Direction Arrow and Position Indicator on all Stops</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Home Landing</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Phase Failure and Phase Reversal Protection</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Repeated Door Closing in the event of Lock Failure</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Door Protection by Light Curtain Full length 94 beams</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Emergency Lighting</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Intercom Car to Machine Room</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Overload Indicator</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Automatic Rescue Device</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Overload Indicator</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Compulsory Stop</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Duplex, Triplex, Quadruplex Control</div>
                <div class="adjust">< FaArrowRightLong style={{ marginRight: "3%" }} />Direction Pre-announcing with Arrival Going</div>
            </div>,
            contentimg: "https://www.technoelevators.com/images/passenger_elevator_auto_door_02.png"
        },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/cc03d250393873.58cfc758587ce.jpg", title: "Elevator interior design" ,link:"escalators-interior"
    ,
       
    content: <div class="col-md-8">


<h6>Theme and Aesthetics</h6>
<p>Choose a theme or aesthetic that aligns with the building's overall design or the brand's identity.</p>

<h6>Lighting</h6>
<p>Proper lighting can make a small space feel larger and more inviting.</p>

<h6>Materials and Finishes</h6>
<p>Select durable and easy-to-clean materials like stainless steel, glass, wood veneer, and high-quality plastics.</p>

<h6>Safety Features</h6>
<p>Ensure that handrails are sturdy, flooring is slip-resistant, and there are clear emergency instructions.</p>

<h6>Branding and Signage</h6>
<p>Incorporate branding elements, logos, or signage discreetly into the design.</p>

<h6>Accessibility</h6>
<p>Make sure the elevator is accessible to everyone, including people with disabilities.</p>

<h6>Artwork and Decor</h6>
<p>Add artwork, decorative panels, or planters to enhance the aesthetics of the elevator.</p>

<h6>Technology Integration</h6>
<p>Integrate modern technology like touchscreen displays or music systems.</p>

<h6>Maintenance and Cleaning</h6>
<p>Consider ease of maintenance and cleaning when choosing materials and finishes.</p>


</div>,
            contentimg: "https://www.technoelevators.com/images/passenger_elevator_auto_door_02.png"
    
    
    },

    ]

    return (
        <>
            {products.map((item) => {
                return (
                    <>
                        {item.link === lastRouteName ?


                            <Container fluid className='mb-5'>
                                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                    

                                    <Col lg={11} className='product-inside-page' >
                                       
                                                <div className='text-center mt-5 mb-3  section-title'>
                  
                  <p>{item.title}</p>
                    </div>
                                                
                                                
                                              
                                    </Col>
                                    <Col lg={11}>
                                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Col lg={9} className='mt-3'>
                                                {item.content}
                                            </Col>
                                            <Col lg={3}>
                                                <img src={item.contentimg} />
                                            </Col>
                                            <Col lg={12}>
                                                {item.content2}
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>

                            </Container>






                            : null
                        }

                    </>
                )
            })}



        </>



    )
}

export default Elevator
