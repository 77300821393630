import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from './Homepages/Footer';

const ServiceSection = () => {
  return (
    <>
    <section className="service_section layout_padding mt-5 mb-5">
      <div className="service_container">
        <Container>
          <div className="heading_container">
            <div className='section-title'>
             <p>Our Services</p> 
            </div>
            {/* <p>
              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration
            </p> */}
          </div>
          <Row>
            <Col md={6} style={{paddingLeft:'7px'}}>
              <div className="box">
                <div className="img-box">
                  <img src="https://cdn-icons-png.freepik.com/256/3073/3073775.png?uid=P96277885&ga=GA1.1.769605160.1678772043&" alt="" />
                </div>
                <div className="detail-box">
                  <h5 >World-Class Elevator & Escalator Technology</h5>
                  <p style={{height:'120px'}}>
                  Hyundai Elevator introduce cutting-edge technology to guarantee safety and reliability, green technology to consider humans and nature, smart technology combined with Korea's No. 1 IT technology makes it a world-class company.
                  </p>
                 
                </div>
              </div>
            </Col>
            <Col md={6} style={{paddingRight:'7px'}}>
              <div className="box">
                <div className="img-box">
                  <img src="https://cdn-icons-png.freepik.com/256/2189/2189976.png?uid=P96277885&ga=GA1.1.769605160.1678772043&" alt="" />
                </div>
                <div className="detail-box">
                  <h5 style={{height:'48px'}}>Reliable Manufacturing System</h5>
                  <p style={{height:'120px'}}>
                  The manufacturing plants in Korea(46,484㎡) and in China(28.716㎡) are equipped with state-of-the-art facilities and techniques to supply high-quality products.
                  </p>
                 
                </div>
              </div>
            </Col>
            <Col md={6} style={{marginTop:'-1.8%',paddingLeft:"7px"}}>
              <div className="box">
                <div className="img-box">
                  <img src="https://cdn-icons-png.freepik.com/256/5087/5087199.png?uid=P96277885&ga=GA1.1.769605160.1678772043&" alt="" />
                </div>
                <div className="detail-box">
                  <h5 style={{height:'48px'}}>Quality and Safety Certified by the World</h5>
                  <p style={{height:'120px'}}>
                  Safety is the most important value of Hyundai Elevator, to make the elevator safer and more comfortable, we thoroughly verify the quality from the development stage.
                  </p>
                 
                </div>
              </div>
            </Col>
            <Col md={6} style={{marginTop:'-1.8%',paddingRight:'7px'}}>
              <div className="box">
                <div className="img-box">
                  <img src="https://cdn-icons-png.freepik.com/256/1571/1571327.png?uid=P96277885&ga=GA1.1.769605160.1678772043&" alt="" />
                </div>
                <div className="detail-box">
                  <h5 style={{height:'48px'}}>Eco-Friendly Energy Saving</h5>
                  <p style={{height:'120px'}}>
                  Power saving applications of Hyundai Elevator such as the Regenerative inverter have been recognized worldwide, earned the Class A certification from a German testing and certification institution(TÜV SÜD).
                  </p>
                 
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default ServiceSection;
