import React, { useEffect } from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import { Link, useLocation } from 'react-router-dom';
import Footer from './Homepages/Footer';
import AOS from 'aos';


function Products() {

    const products = [
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4d6de4133932561.61ca03fceac2c.jpg", title: "Capsule Elevators", link: "capsule-elevators" },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8fa263177262991.64d383c2cc16d.gif", title: "ELEVATOR INTERIOR DESIGN", link: "passenger-Elevators" },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/40b9c124798881.5633a41426cfa.jpg", title: "Elevator visualization.", link: "passenger-elevators-autodoor" },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/cc03d250393873.58cfc758587ce.jpg", title: " Elevator interior design", link:"escalators-interior" },

    ]


    const products2 = [
        { image: "https://img.freepik.com/premium-photo/modern-escalators_323624-188.jpg?w=1060", title: "long escalator", link: "long-escalator" },
        { image: "https://img.freepik.com/premium-photo/mechanical-marvel-detail-shot-reveals-escalator-urban-building-subway-station_892776-1984.jpg?w=900", title: "Outdoor Escalators", link: "outdoor-escalators" },
        { image: "https://img.freepik.com/premium-photo/modern-luxury-escalators-with-staircase_49071-941.jpg?w=900", title: "Modern luxury escalators", link: "passenger-elevators-autodoor" },
        { image: "https://img.freepik.com/free-photo/low-angle-shot-escalator-going-up-metro-station-vijzelgracht-netherlands_181624-4984.jpg?t=st=1713350524~exp=1713354124~hmac=2e8c6dd4a4b62a92987066f26908bef6a75f978c5cfa2bb4683e41e387a03a19&w=900", title: "Escalator & Moving Walkway", link:"escalators-interior" },

    ]

    const location = useLocation()

    const locationpathname = location.pathname
 
useEffect(() => {
    AOS.init({
        duration: 1000, // Animation duration
        once: false, // Whether animation should be triggered only once
        easing: 'ease-out-back' // Easing function
    });
    AOS.refresh(); // Refresh AOS to detect new elements
},[locationpathname]);

if(locationpathname == "/elevators"){
    return (


        <div>

            
            <Container fluid className='mb-5'>
                <Row style={{ display: "flex", justifyContent: 'center' }}>

                    <Col lg={11} >
                     
                        <Row>
                            {/* <h3 className='mt-5 mb-3 elevator-heading'>Elevator</h3> */}
                            <div className='text-center mt-5 mb-3  section-title'>
                  
                  
                  <p >Elevators</p> 
                    </div>

                            <p className='mt-3 mb-4 text-center'>Techno Elevators offers innovative elevator solutions for vertical transportation needs, blending cutting-edge technology with reliable performance for seamless mobility, Techno Elevators provides a comprehensive range of products tailored to enhance safety, comfort, and efficiency in any building environment.</p>
                            {
                                products.map((item) => {
                                    return (
                                        <Col lg={3} className='products-col-4 '  data-aos="zoom-in-up">
                                            <Card className='card-container' as={Link} to={item.link}>
                                                
                                                <img src={item.image} />
                                                <div className='mt-2'>
                                                <p className='card-title-elevator'>{item.title}</p>

                                                    <h6>Read More</h6>
                                                </div>
                                            </Card>

                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>          
            <Footer/>  

        </div>
    )
}




if(locationpathname == "/escalators"){
    return (


        <div>

            
            <Container fluid className='mb-5'>
                <Row style={{ display: "flex", justifyContent: 'center' }}>

                    <Col lg={11} >
                     
                        <Row>
                            {/* <h3 className='mt-5 mb-3 elevator-heading'>Elevator</h3> */}
                            <div className='text-center mt-5 mb-3  section-title'>
                  
                  
                  <p >Escalator</p> 
                    </div>

                            <p className='mt-3 mb-4 text-center'>Techno Escalator offers innovative Escalator solutions for vertical transportation needs, blending cutting-edge technology with reliable performance for seamless mobility, Techno Elevators provides a comprehensive range of products tailored to enhance safety, comfort, and efficiency in any building environment.</p>
                            {
                                products2.map((item) => {
                                    return (
                                        <Col lg={3} className='products-col-4 '  data-aos="zoom-in-up">
                                            <Card className='card-container' as={Link} to={item.link}>
                                                
                                                <img src={item.image} />
                                                <div className='mt-2'>
                                                <p className='card-title-elevator'>{item.title}</p>

                                                    <h6>Read More</h6>
                                                </div>
                                            </Card>

                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>          
            <Footer/>  

        </div>
    )
}
}

export default Products
