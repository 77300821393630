import React, { useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { FaArrowRightLong } from "react-icons/fa6";
import { connect } from 'react-redux';


function Escalator() {

    const location = useLocation();
    const pathSegments = location.pathname.split('/').filter(segment => segment.trim() !== '');
    const lastRouteName = pathSegments[pathSegments.length - 1];


    console.log(location)
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [lastRouteName]);

    const products = [
        {
            image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4d6de4133932561.61ca03fceac2c.jpg", title: "Long Escalators", link: "long-escalator",

            content: 
            <div class="long-escalator-content">
    
    <p>Long escalators are not just a means of transportation; they are a blend of technology, design, and functionality. These marvels of modern engineering can span several floors, providing a convenient and efficient way for people to move between different levels of a building or transit hub.</p>
    
    <h6>Design and Structure</h6>
    <p>Long escalators are typically designed with durability and safety in mind. They are constructed using high-quality materials like stainless steel and tempered glass to withstand heavy foot traffic and the wear and tear of daily use. The steps are engineered to be slip-resistant, and handrails are provided for added safety and stability.</p>
    
    <h6>Operation and Mechanics</h6>
    <p>The operation of a long escalator is powered by a motor that drives a continuous loop of steps. Sensors and safety mechanisms are integrated into the system to detect any anomalies or obstructions, automatically stopping the escalator to prevent accidents.</p>
    
    <h6>Benefits</h6>
    <ul>
        <li><strong>Efficiency:</strong> Long escalators can transport a large number of people quickly and efficiently, reducing congestion and improving the flow of foot traffic in busy areas.</li>
        <li><strong>Accessibility:</strong> They make it easier for people with mobility issues, parents with strollers, and travelers with luggage to navigate multi-level structures without the need for stairs.</li>
        <li><strong>Space-saving:</strong> Compared to elevators, escalators take up less space, making them a more practical choice for buildings with limited floor space.</li>
    </ul>
    
    <h6>Famous Long Escalators Around the World</h6>
    <ul>
        <li><strong>Angel Station, London:</strong> This escalator is one of the longest in Western Europe, descending 27.5 meters (90 feet) underground.</li>
        <li><strong>Park Pobedy Station, Moscow:</strong> Known for having the longest escalator in Europe, with a vertical rise of 126 meters (413 feet).</li>
        <li><strong>Central-Mid-Levels Escalator, Hong Kong:</strong> The longest outdoor covered escalator system in the world, spanning over 800 meters (2,600 feet).</li>
    </ul>
    
    <h6>Conclusion</h6>
    <p>Long escalators are an integral part of urban infrastructure, providing a seamless and efficient way for people to move between different levels of buildings and transit systems. With their innovative design, robust construction, and user-friendly features, long escalators continue to play a significant role in shaping modern cities and enhancing the overall commuting experience for millions of people worldwide.</p>
</div>

            
            
            , contentimg: "https://img.freepik.com/free-photo/escalators-building_23-2149397596.jpg?t=st=1714029524~exp=1714033124~hmac=fb9f8859047319e4425dc39e692ea5bc85b13d81926069be2c3af8866a982fb4&w=360",

           
        },
        {
            image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8fa263177262991.64d383c2cc16d.gif", title: "Outdoor Escalators", link: "outdoor-escalators",
            content: <div class="outdoor-escalator-content">
            
            <p>Outdoor escalators offer a unique solution to urban mobility, providing a convenient and efficient way for people to navigate hilly terrains, parks, and other outdoor environments. These escalators combine the functionality of traditional indoor escalators with the ruggedness and weatherproofing required for outdoor use.</p>
            
            <h6>Design and Structure</h6>
            <p>Outdoor escalators are designed with special materials and features to withstand various weather conditions, including rain, snow, and extreme temperatures. They are constructed using corrosion-resistant materials like galvanized steel and weatherproof coatings to ensure durability and longevity.</p>
            
            <h6>Operation and Mechanics</h6>
            <p>The operation of outdoor escalators is similar to that of indoor escalators, with a motor driving a continuous loop of steps. However, outdoor escalators are equipped with additional weatherproofing and safety features, such as drainage systems and temperature sensors, to maintain optimal performance in outdoor settings.</p>
            
            <h6>Benefits</h6>
            <ul>
                <li><strong>Accessibility:</strong> Outdoor escalators make it easier for people of all ages and physical abilities to access outdoor spaces, parks, and scenic viewpoints without the need to climb steep slopes or stairs.</li>
                <li><strong>Urban Planning:</strong> They can be integrated into urban planning and landscape design to connect different levels of a city or enhance public spaces.</li>
                <li><strong>Environmental Impact:</strong> Outdoor escalators can reduce the carbon footprint by encouraging people to use public transportation and reducing the need for cars in urban areas.</li>
            </ul>
            
            <h6>Famous Outdoor Escalators Around the World</h6>
            <ul>
                <li><strong>Mid-levels Escalator, Hong Kong:</strong> The longest outdoor covered escalator system in the world, connecting the central business district with residential areas.</li>
                <li><strong>Comuna 13 Escalator, Medellín, Colombia:</strong> A series of outdoor escalators built to improve accessibility in a hilly neighborhood and promote social integration.</li>
                <li><strong>Bergen Funicular, Norway:</strong> Although not a traditional escalator, this outdoor funicular railway serves a similar purpose by transporting people up a steep hillside to enjoy panoramic views of the city.</li>
            </ul>
            
            <h6>Conclusion</h6>
            <p>Outdoor escalators are a versatile and innovative solution for enhancing urban mobility and accessibility in outdoor environments. With their robust design, weatherproof features, and numerous benefits, outdoor escalators are becoming increasingly popular in cities around the world, offering a convenient and sustainable transportation option for residents and tourists alike.</p>
        </div>
        ,
            contentimg: "https://5.imimg.com/data5/SK/QD/MY-30804455/outdoor-escalator-500x500.jpg"
        },
        {
            image: "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/40b9c124798881.5633a41426cfa.jpg", title: "Modern Luxury Escalators", link: "passenger-elevators-autodoor",

            content:<div class="modern-luxury-escalator-content">
            
            <p>Modern luxury escalators are designed to offer not just functionality but also elegance and sophistication. These escalators are often featured in upscale shopping malls, luxury hotels, and premium office buildings, adding a touch of luxury to the overall ambiance.</p>
            
            <h6>Design and Features</h6>
            <p>With sleek designs, high-quality materials, and advanced technologies, modern luxury escalators stand out in terms of aesthetics and performance. They often feature custom lighting, glass panels, and premium finishes to create a luxurious and inviting atmosphere.</p>
            
            <h6>Benefits</h6>
            <ul>
                <li><strong>Elegance:</strong> Modern luxury escalators enhance the visual appeal of upscale environments, reflecting a sense of luxury and sophistication.</li>
                <li><strong>Comfort:</strong> Equipped with advanced safety and comfort features, these escalators offer a smooth and enjoyable riding experience.</li>
                <li><strong>Brand Image:</strong> For businesses, installing luxury escalators can help enhance their brand image and attract high-end clientele.</li>
            </ul>
            
            <h6>Famous Luxury Escalators</h6>
            <ul>
                <li><strong>The Dubai Mall, UAE:</strong> Features a stunning waterfall and aquarium view escalator, creating a luxurious shopping experience.</li>
                <li><strong>Saks Fifth Avenue, New York:</strong> Known for its opulent escalators with designer finishes and custom lighting.</li>
                <li><strong>Marina Bay Sands, Singapore:</strong> The hotel's luxury escalators offer panoramic views of the city skyline, adding to the overall luxury experience.</li>
            </ul>
            
            <h6>Conclusion</h6>
            <p>Modern luxury escalators combine functionality with elegance, offering a unique blend of style and performance. Whether it's the sleek design, premium finishes, or advanced features, these escalators redefine the concept of luxury and elevate the overall experience for users in upscale environments.</p>
        </div>
        ,
            contentimg: "https://img.freepik.com/premium-photo/modern-luxury-escalators-with-staircase_49071-941.jpg?w=900"
        },
        { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/cc03d250393873.58cfc758587ce.jpg", title: "Escalator & Moving Walkway" ,link:"escalators-interior"
    ,
       
    content: <div class="col-md-8">
<h6>Theme and Aesthetics</h6>
<p>Choose a theme or aesthetic that aligns with the building's overall design or the brand's identity.</p>

<h6>Lighting</h6>
<p>Proper lighting can make a small space feel larger and more inviting.</p>

<h6>Materials and Finishes</h6>
<p>Select durable and easy-to-clean materials like stainless steel, glass, wood veneer, and high-quality plastics.</p>

<h6>Safety Features</h6>
<p>Ensure that handrails are sturdy, flooring is slip-resistant, and there are clear emergency instructions.</p>

<h6>Branding and Signage</h6>
<p>Incorporate branding elements, logos, or signage discreetly into the design.</p>

<h6>Accessibility</h6>
<p>Make sure the elevator is accessible to everyone, including people with disabilities.</p>

<h6>Artwork and Decor</h6>
<p>Add artwork, decorative panels, or planters to enhance the aesthetics of the elevator.</p>

<h6>Technology Integration</h6>
<p>Integrate modern technology like touchscreen displays or music systems.</p>

<h6>Maintenance and Cleaning</h6>
<p>Consider ease of maintenance and cleaning when choosing materials and finishes.</p>

</div>,
     contentimg: "https://orderlift.net/storage/elevators/escalator-moving-walkway.jpg"
    
    
    },

    ]

    return (
        <>
            {products.map((item) => {
                return (
                    <>
                        {item.link === lastRouteName ?


                            <Container fluid className='mb-5'>
                                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                    

                                    <Col lg={11} className='product-inside-page' >
                                       
                                                <div className='text-center mt-5 mb-3  section-title'>
                  
                  <p>{item.title}</p>
                    </div>
                                                
                                                
                                              
                                    </Col>
                                    <Col lg={11}>
                                        <Row style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Col lg={9} className='mt-3'>
                                                {item.content}
                                            </Col>
                                            <Col lg={3}>
                                                <img src={item.contentimg} width={'100%'}/>
                                            </Col>
                                            <Col lg={12}>
                                                {item.content2}
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>

                            </Container>






                            : null
                        }

                    </>
                )
            })}



        </>



    )
}

export default Escalator
