import React, { createContext, useContext, useState } from 'react'
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import { store } from '../../App';

function GallaryTwo() {

  const [photoIndex, setPhotoIndex] = useState(0);
  // const [isOpen, setIsOpen] = useState(false);


  const [menus, setMenus, isOpen, setIsOpen, navbarclose, setNavbarClose] = useContext(store)

  const products = [
    { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/4d6de4133932561.61ca03fceac2c.jpg", title: "Capsule Elevators", link: "capsule-elevators", id: 1 },
    { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/8fa263177262991.64d383c2cc16d.gif", title: "ELEVATOR INTERIOR DESIGN", link: "passenger-Elevators", id: 2 },
    { image: "https://mir-s3-cdn-cf.behance.net/project_modules/fs/cc03d250393873.58cfc758587ce.jpg", title: " Elevator interior design", id: 3 },
    { image: "https://img.freepik.com/premium-photo/attractive-caucasian-woman-with-long-hair-mobile-hand-pushing-button-lift_447912-699.jpg?w=900", title: " Elevator interior design", id: 4 },
    { image: "https://media.istockphoto.com/id/1006701996/photo/next-level-up.jpg?s=1024x1024&w=is&k=20&c=812CktqPSW234qEqu8UUfbkFIR7bQzBiy8PoXb4-I-c=", title: "Capsule Elevators", link: "capsule-elevators", id: 5 },
    { image:"https://media.istockphoto.com/id/182216823/photo/pushing-elevator-button.jpg?s=1024x1024&w=is&k=20&c=ztH-6x9mw5njpG6fKdXOPxoUdLxpiPCq-GaW5u4c93I=", title: "ELEVATOR INTERIOR DESIGN", link: "passenger-Elevators", id: 6 },
    { image: "https://img.freepik.com/premium-photo/3d-illustration-modern-elevator-doors-art-deco-lobby_88088-1922.jpg?w=826", title: "Elevator visualization.", link: "passenger-elevators-autodoor", id: 7},
    { image: "https://img.freepik.com/premium-photo/inside-modern-elevator-shaft_632261-12483.jpg?w=900", title: " Elevator interior design", id: 8 },
    { image: "https://img.freepik.com/free-photo/sleek-escalator-with-orange-steps-contemporary-space_157027-4220.jpg?w=1060", title: "Capsule Elevators", link: "capsule-elevators", id: 9 },
    { image:"https://img.freepik.com/free-photo/view-escalator-underground-station_1359-697.jpg?w=900", title: "ELEVATOR INTERIOR DESIGN", link: "passenger-Elevators", id: 10 },
    { image: "https://img.freepik.com/free-photo/urban-landscape-tokyo-city-with-escalators_23-2149347171.jpg?w=900", title: "Elevator visualization.", link: "passenger-elevators-autodoor", id: 11 },
    { image: "https://img.freepik.com/premium-photo/woman-pushing-cart-up-escalator-shopping-idea_872147-16805.jpg?w=900", title: " Elevator interior design", id: 12},
    { image: "https://img.freepik.com/premium-photo/inside-futuristic-steel-elevator-with-lights-going-up-generative-ai_634358-804.jpg?w=1060", title: "Capsule Elevators", link: "capsule-elevators", id: 13},
    { image:"https://img.freepik.com/premium-photo/modern-elevator-interior-empty-office-hotel-hallway-ai_894067-10919.jpg?w=900", title: "ELEVATOR INTERIOR DESIGN", link: "passenger-Elevators", id: 14},
    { image: "https://img.freepik.com/premium-photo/modern-public-space-residential-building-with-elevators-second-floor_636131-240.jpg?w=900", title: "Elevator visualization.", link: "passenger-elevators-autodoor", id: 15 },
    { image: "https://img.freepik.com/premium-photo/man-modern-elevator-presses-button_203337-1102.jpg?w=900", title: " Elevator interior design", id: 16},

  ]


  return (
    <>    
<div>
  <div className="gallery">

    <div className="gallery__strip__wrapper">
      <div className="gallery__strip one">
        <div className="photo">
          {products.slice(0, 4).map((item) => {
            return (
              <>
                <div className="photo__image" as={Link} to={item.link}>
                  <img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} />
                </div>
                <div className="photo__name">
                  {item.title}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>

    <div className="gallery__strip__wrapper">
      <div className="gallery__strip four">
        <div className="photo">
          {products.slice(4, 8).map((item) => {
            return (
              <>
                <div className="photo__image"><img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} /></div>
                <div className="photo__name">{item.title}</div>
              </>
            )
          })}
        </div>
      </div>
    </div>

    <div className="gallery__strip__wrapper">
      <div className="gallery__strip two">
        <div className="photo">
          {products.slice(8, 12).map((item) => {
            return (
              <>
                <div className="photo__image"><img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} /></div>
                <div className="photo__name">{item.title}</div>
              </>
            )
          })}
        </div>
      </div>
    </div>

    <div className="gallery__strip__wrapper">
      <div className="gallery__strip three">
        <div className="photo">
          {products.slice(12, 16).map((item) => {
            return (
              <>
                <div className="photo__image"><img src={item.image} onClick={() => { setIsOpen(true); setPhotoIndex(item.id); setNavbarClose("navbar-close") }} /></div>
                <div className="photo__name">{item.title}</div>
              </>
            )
          })}
        </div>
      </div>
    </div>

  </div>
</div>

      <div>
        {isOpen && (
          <Lightbox
            mainSrc={products[photoIndex].image}
            nextSrc={products[(photoIndex + 1) % products.length].image}
            prevSrc={products[(photoIndex + products.length - 1) % products.length].image}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + products.length - 1) % products.length)
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % products.length)
            }
          />
        )}

      </div>

    </>
  )
}

export default GallaryTwo



















